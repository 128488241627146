// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// $('#class').change(function(){
//  $('select.group-select').hide();
//  var selector = "select[data-classname=" + $(this).val() +"]";
//  $(selector).show();
// })
// $('#class').trigger("change");

// Load Datatables
// import $ from 'jquery';
// import dt from 'datatables.net';
// var $  = require( 'jquery' );
// var dt = require( 'datatables.net' )();
import { loadStripe } from '@stripe/stripe-js';
var $ = require('jquery');
require('datatables.net');
const tables = {
    '#ads-datatable': [
        { data: "goods" },
        { data: "from_country", className: "is-hidden-mobile" },
        { data: "from_locality" },
        { data: "truck", className: "is-hidden-mobile" },
        { data: "after_data" },
        { data: "before_data", className: "is-hidden-mobile" },
        { data: "to_country", className: "is-hidden-mobile" },
        { data: "to_locality" },
    ],

    '#auctions-datatable': [
        { data: "last_price" },
        { data: "beginning" },
        { data: "the_end" },
        { data: "location_load_id" },
        { data: "location_download_id" },
        { data: "merchandise_category", className: "is-hidden-mobile" },
        { data: "truck_category", className: "is-hidden-mobile" },
        { data: "comment", className: "is-hidden-touch" },
        { data: "id", className: "is-hidden-mobile" },
        { data: "created_at", className: "is-hidden-mobile" },

    ],
    '#myoffers-datatable': [
        { data: "beginning" },
        { data: "the_end" },
        { data: "last_price" },
        { data: "country_load_id", className: "is-hidden-mobile" },
        { data: "location_load_id" },
        { data: "country_download_id", className: "is-hidden-mobile" },
        { data: "location_download_id" },
        { data: "merchandise_category", className: "is-hidden-mobile" },
        { data: "truck_category", className: "is-hidden-mobile" },
        { data: "comment", className: "is-hidden-touch" },
    ],

    '#search-datatable': [
        { data: "beginning" },
        { data: "the_end" },
        { data: "last_price" },
        { data: "country_load_id", className: "is-hidden-mobile" },
        { data: "location_load_id" },
        { data: "country_download_id", className: "is-hidden-mobile" },
        { data: "location_download_id" },
        { data: "merchandise_category", className: "is-hidden-mobile" },
        { data: "truck_category", className: "is-hidden-mobile" },
        { data: "comment", className: "is-hidden-touch" },
    ],
}

// require('yadcf')(window, $) // Uncomment if you use yadcf (need a recent version of yadcf)

document.addEventListener("turbolinks:load", async function() {
    const stripe = await loadStripe('pk_test_51IqHu8G4ULTsp8Y4yn9Gm8TgaW2eY3UfhurkDmwSPGn7VSaUmJQNYqPvqw0G1WgJ92eFX2WM87kOw4fr64vxWbR400UdHQ8U1z');
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    document.querySelectorAll('.notification.message .delete').forEach(el => {
        el.addEventListener('click', () => {
            console.log(el);
            el.parentElement.parentElement.remove();
        });
    });

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
        // Add a click event on each of them
        // $(".navbar-item.has-dropdown").click(function() { $(this).children().children().toggle(); });
        $navbarBurgers.forEach(el => {
            el.addEventListener('click', () => {

                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');

            });
        });

    }
    const element = document.querySelector("#new-point");
    if (element) {
        element.addEventListener("ajax:success", (event) => {
            const [data, _status, xhr] = event.detail;
            return stripe.redirectToCheckout({ sessionId: data.id });
        });
        element.addEventListener("ajax:error", () => {
            alert('Error');
        });
    }

    for (const table in tables) {
        if (!$.fn.DataTable.isDataTable(table)) {
            $(table).DataTable({

                "processing": true,
                "pagingType": "full_numbers",

                "sPaginationType": "four_button",
                "dt-init pagingType": 'dt-string full',
                "info": true,
                // "dom": '<"top"lf><t><"bottom"ip>',
                // "dom": '<"top"iflp<"clear">>rt<"bottom"iflp<"clear">>',
                "dom": '<fl<t>ip>',
                "lengthMenu": [
                    [5, 10, 50, -1],
                    [5, 10, 50, "All"]
                ],
                //scrollY: 320,
                paging: true,
                searching: true,
                serverSide: true,
                fixedHeader: true,

                "ajax": {
                    "url": $(table).data('source')
                },
                "language": $(table).data('translations'),

                "columns": tables[table]
                    // pagingType is optional, if you want full pagination controls.
                    // Check dataTables documentation to learn more about
                    // available options.
            });
        }

        // $(table).ready(function() {
        //   var table = $('#ad-datatable').DataTable();

        //   table.rows().every(function(rowIdx, tableLoop, rowLoop) {
        //     var cell = table.cell({
        //       row: rowIdx,
        //       column: 0
        //     }).node();
        //     $(cell).addClass('warning');
        //   });
        // });

    }

    // $('#auctions-datatable').DataTable({
    //   "processing": true,
    //   "serverSide": true,
    //   "ajax": {
    //     "url": $('#auctions-datatable').data('source')
    //   },
    //   "pagingType": "full_numbers",
    //   "columns": [
    //     {"data": "beginning"},
    //     {"data": "the_end"},
    //   ]
    //   // pagingType is optional, if you want full pagination controls.
    //   // Check dataTables documentation to learn more about
    //   // available options.
    // });
});

document.addEventListener("turbolinks:before-cache", function() {
    for (const table in tables) {
        $(table).DataTable().destroy();
    }
})